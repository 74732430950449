<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage institutions</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Institutions
                </div>
              </div>
              <div
                class="breadcrumb-right"
                v-if="checkIsAccessible('institution', 'create')"
              >
                <div class="card-toolbar">
                  <v-btn
                    @click="openDialog()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    institution
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    v-on:keyup.enter="getInstitutions()"
                    outlined
                    v-model="search.title"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    v-on:keyup.enter="getInstitutions()"
                    v-model="search.active"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    @click.prevent="getInstitutions()"
                    class="btn btn-primary btn-block w-35"
                    :loading="loading"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-1">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(institution, index) of institutions" :key="index">
                    <td class="px-3 wrap-column">
                      <a href="#" @click="openDialog(institution.id)" class="mr-2">
                        {{ institution.title }}</a
                      >
                      &nbsp;
                      <i
                        class="fas fa-circle"
                        :class="
                          institution.is_active == true ? 'dot-active' : 'dot-inactive'
                        "
                      ></i>
                    </td>
                    <td class="">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="checkIsAccessible('institution', 'edit')"
                              @click="openDialog(institution.id)"
                            >
                              <a class="navi-link" @click="openDialog(institution.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="checkIsAccessible('institution', 'delete')"
                            >
                              <a
                                class="navi-link"
                                @click.prevent="deleteInstitution(institution.id)"
                              >
                                <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-if="institutions.length > 0"
                class="pull-right mt-7"
                @input="getInstitutions"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
          <AddupdateInstitution
            ref="addOrUpdateInstitution"
            @refresh="getInstitutions"
            @close_dialog="closeDialog"
          ></AddupdateInstitution>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import InstitutionServiceService from "@/core/services/institution/InstitutionService";

import AddupdateInstitution from "./CreateOrUpdate";

const institutionServiceService = new InstitutionServiceService();

export default {
  name: "Insitutions",
  components: {
    AddupdateInstitution,
  },
  data() {
    return {
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      search: {
        active: "active",
      },
      institutions: [],
      institution_id: null,
      page: null,
      total: null,
      perPage: null,
      loading: false,
    };
  },
  mounted() {
    this.getInstitutions();
  },
  methods: {
    getInstitutions() {
      this.loading = true;
      institutionServiceService
        .paginate(this.search)
        .then((response) => {
          this.institutions = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    deleteInstitution(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            institutionServiceService
              .delete(id)
              .then((response) => {
                this.$snotify.success("Information deleted");
                this.getInstitutions();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },

    openDialog(id = null) {
      this.institution_id = id;
      this.$refs["addOrUpdateInstitution"].showModal(this.institution_id);
    },
    closeDialog(id) {
      this.institution_id = id;
      this.$refs["addOrUpdateInstitution"].hideModal();
      this.getInstitutions();
      this.institution_id = null;
    },
  },
};
</script>
