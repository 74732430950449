<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{`${edit?'Update':"Add"} institution`}}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="cancelForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="row">
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model="institution.title"
                label="Institution title"
              ></v-text-field>
            </v-col>
<!--            <v-col cols="12">-->
<!--              <v-text-field-->
<!--                outlined-->
<!--                dense-->
<!--                v-model="institution.type"-->
<!--                label="Type"-->
<!--              ></v-text-field>-->
<!--            </v-col>-->

            <v-col cols="6">
              <v-switch
                v-model="institution.is_active"
                name="check-button"
                label="Status"
                >
              </v-switch>
            </v-col>

            <div class="col-12 text-right mt-4">
              <v-btn class="btn btn-standard text-gray cancel-btn" depressed @click="cancelForm"
                >Cancel
              </v-btn>
              <v-btn v-if="checkIsAccessible('institution', 'create')"
                class="text-white ml-2 btn btn-primary"
                depressed
                @click="saveOrUpdate"
                :loading="isBusy"
                >Save
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import InstitutionServiceService from "@/core/services/institution/InstitutionService";
import { required } from "vuelidate/lib/validators";

const institutionServiceService = new InstitutionServiceService();
export default {
  validations: {
    institution: {
      title: { required },
      is_active: { required },
    },
  },
  data() {
    return {
      isBusy: false,
      edit: false,
      dialog: false,
      institution_id: null,
      institution: {
        id: null,
        title: null,
        type: null,
        address: null,
        is_active: true,
      },
    };
  },
  mounted() {},
  methods: {
    cancelForm(){
      this.resetData()
      this.hideModal()
    },
    getInstitution(id) {
      institutionServiceService.show(id).then((response) => {
        this.edit = true;
        this.institution = response.data.institution;
        this.institution.is_active ? (this.institution.is_active = true) : false;
        this.$refs["my-modal"].show();
      });
    },
    showModal(id = null) {
      this.institution_id = id;
      if (this.institution_id) {
        this.getInstitution(this.institution_id);
      }else{
        this.resetData();
      }
      this.dialog = true;
    },
    hideModal() {
      this.dialog = false;
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden

      this.$refs["my-modal"].toggle("#toggle-btn");
    },

    saveOrUpdate() {
      this.$v.institution.$touch();
      if (this.$v.institution.$error) {
        setTimeout(() => {
          this.$v.institution.$reset();
        }, 3000);
      } else {
        this.institution.id ? this.updateInstitution() : this.saveInstitution();
      }
    },
    saveInstitution() {
      this.isBusy = true;
      institutionServiceService.store(this.institution).then((response) => {
        this.$snotify.success("Information added");
        this.edit = false;
        this.dialog = false;
        this.isBusy = false;
        this.resetData();
        this.$emit('refresh');
      });
    },
    resetData(){
      this.institution={
          id: null,
          title: null,
          type: null,
          address: null,
          is_active: true,
      }
      this.teacher = [];
      this.edit = false;
      this.$v.$reset();
    },
    updateInstitution() {
      this.isBusy = true;
      institutionServiceService
        .update(this.institution.id, this.institution)
        .then((response) => {
          this.edit = false;
          this.$snotify.success("Information updated");
          this.dialog = false;
          this.isBusy = false;
          this.resetData();
          this.$emit('refresh');
        });
    },
  },
};
</script>
